import React from 'react'

const Signature = ({ data }) => {
  let signatureTemplate = `
    <tbody>
      <tr>
        <td width="110" style="border-right: solid 2px #62ebc4; text-align: center;">
          <a href="https://www.klipr.io"><img src="https://signature.klipr.io/2020/icons/logo.png" width="90" border="0" style="width: 90px; display: block; padding: 7px; margin: 0 auto; border: none;"/></a>
        </td>
        <td width="330" style="padding: 5px 10px;">
          <table cellpadding="0" cellspacing="0" border="0" style="background: none; border: 0px; margin: 0px; padding: 0px;">
            <tbody>
              <tr>
                <td colspan="3" style="margin: 0; font-size: 20px; font-weight: bold; color: #fc425d;">##name##</td>
              </tr>
              <tr>
                <td colspan="3" style="margin: 0 0 5px; font-size: 16px; color: #404040;">##title##</td>
              </tr>
              <tr>
                <td width="20">
                  <img src="https://signature.klipr.io/2020/icons/phone.png" width="14" border="0" style="width: 14px; border: none;"/>
                </td>
                <td colspan="2">
                  <a href="##phonelink##" style="font-size: 12px; font-weight: bold; margin: 0; color: #404040; text-decoration: none;">##phone##</a>
                </td>
              </tr>
              <tr>
                <td width="20" style="padding-bottom: 10px">
                  <img src="https://signature.klipr.io/2020/icons/mail.png" width="14" border="0" style="width: 14px; display: block; border: none;"/>
                </td>
                <td colspan="2" style="padding-bottom: 15px">
                  <a href="##emaillink##" style="font-size: 12px; font-weight: bold; margin: 0; color: #404040; text-decoration: none;"><span style="color: #fc425d">##emailaddress##</span>##emaildomain##</a>
                </td>
              </tr>
              <tr>
                <td width="20">
                  <a href="https://twitter.com/KliprHQ"><img src="https://signature.klipr.io/2020/icons/twitter.png" width="16" height="16" border="0" style="width: 16px; height: 16px; border: none;"/></a>
                </td>
                <td width="20">
                  <a href="https://www.linkedin.com/company/klipr"><img src="https://signature.klipr.io/2020/icons/linkedin.png" width="16" height="16" border="0" style="width: 16px; height: 16px; border: none;"/></a>
                </td>
                <td style="vertical-align: middle; color: #9C9C9C; font-size: 8px; margin: 0 0 0 5px; max-width: 275px;">
                  Klipr. Registered Office: 3rd Floor, 86-90 Paul Street, London, EC2A 4NE.
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  `

  signatureTemplate = signatureTemplate.replace('##name##', data.name)
  signatureTemplate = signatureTemplate.replace('##title##', data.title)
  signatureTemplate = signatureTemplate.replace('##phone##', data.phone)
  signatureTemplate = signatureTemplate.replace('##phonelink##', `tel:${data.phone.replace(/ /g, '')}`)
  signatureTemplate = signatureTemplate.replace('##emailaddress##', data.email.split('@')[0])
  signatureTemplate = signatureTemplate.replace('##emaildomain##', `@${data.email.split('@')[1]}`)
  signatureTemplate = signatureTemplate.replace('##emaillink##', `mailto:${data.email}`)

  return (
    <table cellPadding='0' cellSpacing='0' border='0' style={{background: 'none', borderWidth: '0px', border: '0px', margin: '0px', padding: '0px'}} dangerouslySetInnerHTML={{ __html: signatureTemplate }}/>
  )
}

export default Signature
