import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Signature from '../components/signature'
import * as vars from '../styles/export'

const Wrapper = styled.div`
  padding: 0 30px;
`
const StyledForm = styled.form`
  width: 90%;
  max-width: 600px;
  margin: 0 auto -70px;
  height: 550px;

  @media (max-width: ${vars.sizes.mPhablet}) {
    width: 100%;
    height: 420px;
  }

  label {
    display: inline-block;
    text-align: right;
    padding-right: 20px;
    width: 150px;
  }
  input {
    display: inline-block;
    font-size: 1.2rem;
    width: calc(100% - 200px);
    margin-bottom: 20px;
    padding: 10px;
    background: transparent;
    border: none;
    border-bottom: solid 1px ${vars.colors.pink};
    border-radius: 0;
    outline: none;
    color: ${vars.colors.pink};
    transition: all .3s ease;

    &::placeholder {
      color: ${vars.colors.pink};
      opacity: 1;
    }
    &:invalid {
      box-shadow: none;
    }
    &:hover {
      transform: scale(1.02);
    }

    &[type='checkbox'] {
      transform: none;
      width: 20px;
    }
  }
`
const StyledSubmit = styled.button`
  display: block;
  background-color: ${vars.colors.green};
  color: ${vars.colors.black};
  border-radius: 35px;
  font-size: 1.5rem;
  margin-top: 30px;
  cursor: pointer;
  padding: 15px 100px;
  margin: 30px auto;
  transition: all .3s ease-out;
  outline: none;
  appearance: none !important;
  border: none !important;

  &:hover {
    box-shadow: 0px 0px 20px 5px rgba(252,66,93,.3);
    transform: scale(1.02);
  }
`
const SignatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 3;
  width: 80%;
  max-width: 500px;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${vars.colors.white};
  padding: 20px;
  border-radius: 5px;
`
const Overlay = styled.div`
  z-index: 2;
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0,0,0,.7);
  cursor: pointer;
`
const Instructions = styled.p`
  position: absolute;
  top: -60px; left: 50%;
  text-align: center;
  width: 90%;
  background-color: ${vars.colors.white};
  padding: 5px 20px;
  border-radius: 5px;
  transform: translateX(-50%);
`
const PostInstructions = styled.p`
  position: absolute;
  bottom: -60px; left: 50%;
  text-align: center;
  width: 90%;
  background-color: ${vars.colors.white};
  padding: 5px 20px;
  border-radius: 5px;
  transform: translateX(-50%);
`

const IndexPage = props => {
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    email: '',
    phone: '020 3950 2164'
  })
  const [showSignature, setSignatureVisibility] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('form_data')) {
      setFormData(JSON.parse(localStorage.getItem('form_data')))
    }
  }, [])

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }
  const generateSignature = e => {
    if (document.getElementById('signatureForm').checkValidity()) {
      e.preventDefault()
      e.stopPropagation()

      localStorage.setItem('form_data', JSON.stringify(formData))
      setSignatureVisibility(true)
    }
  }

  return (
    <Wrapper>
      <StyledForm id='signatureForm'>
        <label htmlFor='name'>Name</label>
        <input type='text' name='name' id='name' value={formData.name} onChange={handleChange} required/>
        <label htmlFor='title'>Job Title</label>
        <input type='text' name='title' id='title' value={formData.title} onChange={handleChange} required/>
        <label htmlFor='email'>Email</label>
        <input type='email' name='email' id='email' value={formData.email} onChange={handleChange} required/>
        <label htmlFor='phone'>Phone</label>
        <input type='text' name='phone' id='phone' value={formData.phone} onChange={handleChange} required/>
        <StyledSubmit onClick={generateSignature}>Generate Signature</StyledSubmit>
      </StyledForm>

      {showSignature && (
        <div>
          <Overlay onClick={() => setSignatureVisibility(false)}/>
          <SignatureWrapper>
            <Instructions>Select and copy the content of the white box below:</Instructions>
            <Signature data={formData}/>
            <PostInstructions>And then paste it in the signature settings section.</PostInstructions>
          </SignatureWrapper>
        </div>
      )}
    </Wrapper>
  )
}

export default IndexPage
